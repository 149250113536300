<template>
    <section class="login">
        <div class="container-scroller">
            <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div class="content-wrapper d-flex align-items-center auth">
                    <div class="row w-100 flex-grow">
                        <div class="col-xl-4 col-lg-6 mx-auto">
                            <div class="auth-form-light text-left p-5">
                                <div class="brand-logo text-center">

                                    <img src="../../../assets/images/watch-logo.png"/>
                                </div>
                                <form class="pt-3">
                                    <b-form-group
                                            id="input-group-1"
                                            label="Email Address"
                                            label-for="input-1"
                                    >
                                        <b-form-input
                                                id="input-1"
                                                type="email"
                                                placeholder="Email Address"
                                                v-model="$v.email.$model"
                                                :state="$v.email.$dirty ? !$v.email.$error : null"
                                                aria-describedby="input-2-live-feedback"
                                        ></b-form-input>
                                        <b-form-invalid-feedback v-if="!$v.email.required" id="input-2-live-feedback">
                                            {{ $t("field-required") }}
                                        </b-form-invalid-feedback>
                                        <b-form-invalid-feedback v-if="!$v.email.email" id="input-2-live-feedback">
                                            Invalid email format
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                    <b-form-group
                                            id="input-group-2"
                                            label="Password"
                                            label-for="input-2"
                                    >
                                          <span class="icon is-small is-right"
                                                style="">
                                        <b-form-input
                                                id="input-2"
                                                :type="inputType"
                                                placeholder="Password"
                                                v-model="$v.password.$model"
                                                :state="$v.password.$dirty ? !$v.password.$error : null"
                                                aria-describedby="input-3-live-feedback"
                                        ></b-form-input>
                                         <b-form-invalid-feedback v-if="!$v.password.required"
                                                                  id="input-3-live-feedback">{{ $t("field-required") }}</b-form-invalid-feedback>
                                         <b-form-invalid-feedback v-if="!$v.password.minLength"
                                                                  id="input-3-live-feedback">Password must have at least {{ $v.password.$params.minLength.min }} letters.</b-form-invalid-feedback>
                                              <!--                                         <b-form-invalid-feedback v-if="password && !$v.password.valid" id="input-3-live-feedback">{{ $t("Password contains atleast One Uppercase, One Lowercase, One Number and One Special Character.") }}</b-form-invalid-feedback>-->
                                      <i @click="toggleShow" class="fas password-icon"
                                         :class="{'fa fa-eye': showPassword, 'fa fa-eye-slash': !showPassword, }"></i></span>

                                    </b-form-group>
                                    <div class="my-2 d-flex justify-content-between align-items-center">
                                        <div class="form-check">
                                            <label class="form-check-label text-muted">
                                                <input type="checkbox" @click="checkFunction($event)"
                                                       class="form-check-input"/>
                                                By clicking the button, you agree to our
                                                <i class="input-helper"></i>
                                                <span><a href="https://www.watchspotterpro.com/terms-and-conditions/"
                                                         target="_blank">Terms and Conditions</a></span> and
                                                <span><a href="https://www.watchspotterpro.com/privacy-policy/"
                                                         target="_blank">Privacy Policy</a></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <button type="button" class="btn btn-dark btn-lg btn-block"
                                                :disabled="this.$v.email.$anyError || this.$v.password.$anyError || isChecked === false"
                                                @click="login()">
                                            SIGN IN
                                        </button>
                                    </div>
                                    <div class="my-2 d-flex justify-content-between align-items-center">
                                        <div class="form-check">
                                            <label class="form-check-label text-muted">
                                                <input type="checkbox" v-model="remember_be" class="form-check-input"/>
                                                Remember me
                                                <i class="input-helper"></i>
                                            </label>
                                        </div>
                                        <router-link class="ml-5 auth-link float-right text-info "
                                                     to="/forget-password">
                                            Forgot password?
                                        </router-link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- content-wrapper ends -->

            </div>

            <b-modal id="modalLoginClient" ref="vuemodal" title="Please Select a Project" size="md" hide-footer>
                <form class="forms-sample" @submit.stop.prevent="submitLoginClient" autocomplete="off">
                    <b-form-group label="Project*" label-for="client">
                        <b-form-select v-model="$v.client_form.login_client_id.$model"
                                       :state="$v.client_form.login_client_id.$dirty ? !$v.client_form.login_client_id.$error : null"
                                       aria-describedby="input-1-live-feedback" :options="clientArr"></b-form-select>
                        <b-form-invalid-feedback id="input-1-live-feedback">{{ $t("field-required") }}
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-button type="submit" variant="success" class="mr-2">
                        Submit
                    </b-button>
                </form>
            </b-modal>

            <Footer/>

            <!-- page-body-wrapper ends -->

        </div>
    </section>
</template>


<script>
    import router from "../../../router"
    import API from '@/api'
    import alertError from "../../../components/alerts/sweet-alert/alertError";
    import Footer from "../../../layout/partials/Footer";
    import {validationMixin} from "vuelidate";
    import {helpers, required, minLength, email, sameAs} from "vuelidate/lib/validators";

    export default {
        name: "Login",
        mixins: [validationMixin],
        components: {
            Footer
        },
        data() {
            return {
                email: '',
                password: '',
                disabled: '',
                remember_be: '',
                clientArr: [],
                client_form: {
                    login_client_id: '',
                },
                isChecked: false,
                showPassword: true,
                inputType: 'password',
                token: '',
                clientFullArr: [],
                client_settings: {},
                user: {},
                permission: [],
            };
        }, validations: {
            client_form: {
                login_client_id: {
                    required,
                },
            },
            email: {
                email,
                required,
            }, password: {
                required,
                minLength: minLength(6),
                // valid: function(value) {
                //     const containsUppercase = /[A-Z]/.test(value)
                //     const containsLowercase = /[a-z]/.test(value)
                //     const containsNumber = /[0-9]/.test(value)
                //     const containsSpecial = /[#?!@$%^&*-]/.test(value)
                //     return containsUppercase && containsLowercase && containsNumber && containsSpecial
                // },
            }
        },

        methods: {
            checkFunction(event) {
                if (event.target.checked) {
                    this.isChecked = true;
                } else {
                    this.isChecked = false;
                }

            },
            login() {
                this.$v.email.$touch();
                this.$v.password.$touch();
                if (this.$v.email.$anyError || this.$v.password.$anyError) {
                    return;
                }
                let email = this.email
                let password = this.password
                let remember_be = this.remember_be
                let data = {
                    email: email,
                    password: password,
                    client_url: window.location.href.split('#')[0],
                    remember_be: remember_be
                }
                this.disabled = true;
                API.login(
                    data,
                    data => {
                        console.log(data)
                        localStorage.removeItem('client_id')
                        if (data.user.user_clients_count === 0 && data.user.user_clients.length === 0) {
                            if (typeof data.token !== "undefined") {
                                localStorage.setItem('isLoggedin', data.token)
                                localStorage.setItem('client_settings', JSON.stringify(data.client_settings))
                                localStorage.setItem('user', JSON.stringify(data.user))
                                localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                                localStorage.setItem('user_clients_count', data.user.user_clients_count)
                                if (this.remember_be == 'true') {
                                    localStorage.setItem('email', this.email)
                                    localStorage.setItem('password', this.password)
                                }
                                router.push("/dashboard");
                            } else {
                                this.$swal({
                                    type: 'error',
                                    title: 'Oops...',
                                    text: data.message,
                                })
                            }
                        } else if (data.user.user_clients_count === 1 && data.user.user_clients.length === 1) {
                            if (typeof data.token !== "undefined") {
                                localStorage.setItem('isLoggedin', data.token)
                                localStorage.setItem('client_settings', JSON.stringify(data.user.user_clients[0].clients))
                                localStorage.setItem('user', JSON.stringify(data.user))
                                localStorage.setItem('userPermissions', JSON.stringify(data.permission))
                                var c_id = data.user.user_clients[0].clients.id
                                localStorage.setItem('user_clients_count', data.user.user_clients_count)
                                localStorage.setItem('client_id', c_id)
                                if (this.remember_be == 'true') {
                                    localStorage.setItem('email', this.email)
                                    localStorage.setItem('password', this.password)
                                    localStorage.setItem('remember_client_id', c_id)
                                }
                                router.push("/dashboard");
                            } else {
                                this.$swal({
                                    type: 'error',
                                    title: 'Oops...',
                                    text: data.message,
                                })
                            }
                        } else {
                            if (typeof data.token !== "undefined") {
                                localStorage.setItem('user_clients_count', data.user.user_clients.length)
                                if (data.user.user_clients.length > 0) {
                                    data.user.user_clients.forEach((value, index) => {
                                        const data1 = {
                                            value: (value.clients) ? value.clients.id : '',
                                            text: (value.clients) ? value.clients.name : '',
                                        }
                                        this.clientFullArr.push(value);
                                        this.clientArr.push(data1)
                                    });

                                    localStorage.setItem('clientArr', JSON.stringify(this.clientFullArr))
                                }
                                this.token = data.token;
                                this.permissions = data.permission;
                                this.client_settings = data.client_settings;
                                this.user = data.user;
                                this.$root.$emit("bv::show::modal", "modalLoginClient");

                            } else {
                                this.$swal({
                                    type: 'error',
                                    title: 'Oops...',
                                    text: data.message,
                                })
                            }
                        }

                    },
                    err => {
                        this.$swal({
                            type: 'error',
                            title: 'Oops...',
                            text: err.message,
                        })
                    }
                )
            },
            submitLoginClient() {

                this.$v.client_form.$touch();

                if (this.$v.client_form.$anyError) {
                    return
                }
                if (this.client_form.login_client_id !== '') {

                    var client = this.clientFullArr.find(o => o.client_id === this.client_form.login_client_id);
                    console.log(this.clientFullArr, client);
                    this.client_settings = client.clients;
                    localStorage.setItem('isLoggedin', this.token)
                    localStorage.setItem('client_settings', JSON.stringify(this.client_settings))
                    localStorage.setItem('user', JSON.stringify(this.user))
                    localStorage.setItem('userPermissions', JSON.stringify(this.permissions))
                    localStorage.setItem('client_id', this.client_form.login_client_id)

                    if (this.remember_be == 'true') {
                        localStorage.setItem('email', this.email)
                        localStorage.setItem('password', this.password)
                        localStorage.setItem('remember_client_id', this.client_form.login_client_id)
                    }
                    router.push("/dashboard");
                }
            },
            toggleShow() {
                this.showPassword = !this.showPassword;
                if (this.showPassword) {
                    this.inputType = 'password';
                } else {
                    this.inputType = 'text';
                }
            }
        }
    }
</script>
